import {
  ControllerParams,
  CreateControllerFn,
  IWixAPI,
} from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}: ControllerParams) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  return {
    async pageReady() {
      // Here you can pass props to the Widget
      const instance = controllerConfig.appParams.instance;
      const baseURL = controllerConfig.wixCodeApi.location.baseUrl;
      const appID = controllerConfig.appParams.appDefinitionId;
      controllerConfig.setProps({ instance, baseURL, appID });
    },
  };
};

export default createController;
